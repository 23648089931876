
  import { Vue, Component } from "vue-property-decorator";
  import dayjs from "dayjs";
  import { getMachineNum , searchMachine ,getMachineQrcode,deleteMachineNum} from "@/api/modules/getmachine";
  @Component({
    name: "Eqmanagement",
  })
  export default class Eqmanagement extends Vue {
    private tableData: Array<any> = [];
    private pageNo = 1;
    private pageSize = 10;
    private form = {search:""};
    private queryparam = "";
    private iframe = (input: HTMLElement | null): input is HTMLIFrameElement =>
     input !== null && input.tagName === 'IFRAME';

    
  
    private async mounted() {
    //   let datas = [];
    //   for (let i = 0; i < 400; i++) {
    //     datas.push({
    //       date: dayjs().format("YYYY-MM-DD"),
    //       name: `姓名${i}`,
    //       address: `地址${i}`,
    //     });
    //   }
    //   this.tableData = datas;
      let res =await getMachineNum(1)
      this.tableData = res.list;
      this.pageSize = res.total
      //this.pageNo = res.page_count
      console.log("默认第一页",res)
    }
  
    private handleSizeChange(val: number) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.pageNo = 1;
    }
  
    private handleCurrentChange(val: number) {
      this.pageNo = val;
      console.log(`当前页: ${val}`);
      let str = this.queryparam?"&search="+this.queryparam:""
      let param = val+ str
      console.log(`当前字符串: ${param}`);
       getMachineNum((param)).then((res)=>{
        this.tableData = res.list;
        this.pageSize = res.total
       })
       
    }
  
    //打开二维码
    private async openQrcode(id:string){
      let res = await getMachineQrcode(id)
      let url = res.list[0].qrcode_url
      let _this = this
      console.log(res)
      this.$confirm('<img class="target-el" style="width: 200px; height: 200px; margin-left: 83px;" src='+url+'></img>', '二维码', {
          confirmButtonText: '打印',
          cancelButtonText: '关闭',
          dangerouslyUseHTMLString:true,
        }).then((action)=>{
          _this.print()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '关闭'
          });       
        });
    }
    private removeIframe(){
    const iframeEL=document.querySelector('.target-el-iframe');
	if(iframeEL){
    iframeEL.remove();
	}
}

private print(){
	this.removeIframe();
	//目标元素
	const info=document.querySelector('.target-el');
	//创建iframe
	const iframeEl= document.createElement('iframe');
	iframeEl.className='target-el-iframe';
	//将元素放置最底层，防止其覆盖了其他元素
	iframeEl.style.position="fixed"
	iframeEl.style.zIndex="-99";
	//添加到页面
	document.querySelector('body')?.append(iframeEl)
	
	const documentEl=iframeEl.contentDocument;
	//深度拷贝目标元素，如果不拷贝，则会将目标元素移动到iframe中
	documentEl?.body.append(info?.cloneNode(true)||"")
	
	//去除打印的页眉和页脚
	const style=document.createElement('style')
	style.media="print";
	style.innerText=`
	 @page {
	  size: auto;
	  margin: 0mm;
	}`
	//添加答应内容
	documentEl?.head.append(style);
	//打印
	iframeEl.contentWindow?.print()
}

    private  open(id:string) {
        this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
        let res1 = await deleteMachineNum(id)
        //刷新第一页
        this.pageNo =1
        let res =await getMachineNum(this.pageNo+this.queryparam?"":"&search="+this.queryparam)
        this.tableData = res.list;
        this.pageSize = res.total
        if(res1.code==200){
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }else{
          this.$message({
            type: 'info',
            message: '删除失败请联系后台！'
          });     
        }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
      }
    

    private async onSubmit() {
      this.pageNo = 1
      console.log(this.form);
      let res =await searchMachine(this.form.search.match(/^[ ]*$/)?"":(this.form.search));
      this.queryparam = this.form.search.match(/^[ ]*$/)?"":this.form.search
      console.log(res);
      this.tableData = res.list;
      this.pageSize = res.total
    }
  }
  